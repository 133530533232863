<template>
  <base-section id="404">
    <base-heading title="404" />

    <base-subheading
      class="primary--text"
      title="Eh!"
    />

    <base-subheading
      space="8"
      title="Wygląda na to że podana strona nie istnieje"
    />

    <base-img
      :src="require('@/assets/logo.svg')"
      class="mx-auto mb-12"
      contain
      height="350"
      width="250"
    />

    <div class="text-center">
      <base-btn @click="goToHome">
        Zabierz mnie stąd
      </base-btn>
    </div>
  </base-section>
</template>

<script>
  import { PL, EN } from '@/constants/lang.js'

  export default {
    name: 'FourOhFour',

    provide: {
      heading: { align: 'center' },
    },
    methods: {
      goToHome () {
        const name = this.$route.meta.lang === PL ? 'Home-pl' : 'Home-en'
        if (this.$route.name !== name) {
          this.$router.push({ name })
        }
      },
    },
  }
</script>
